export const ScheduleStatus = {
  STARTED: 0,
  CARRIED_OUT: 1,
  LATE: 2,
  CANCELED: 3,
  RESCHEDULED: 5,
  INDEFINITE: 4,
}

export const ScheduleStatusClassColors = {
  [ScheduleStatus.STARTED]: 'blue darken-1',
  [ScheduleStatus.CARRIED_OUT]: 'teal darken-1',
  [ScheduleStatus.LATE]: 'orange lighten-2',
  [ScheduleStatus.CANCELED]: 'red lighten-2',
  [ScheduleStatus.RESCHEDULED]: 'yellow darken-1',
  [ScheduleStatus.INDEFINITE]: 'blue-grey darken-1',
}

export const ScheduleStatusText = {
  [ScheduleStatus.STARTED]: 'Planejada',
  [ScheduleStatus.CARRIED_OUT]: 'Realizada',
  [ScheduleStatus.LATE]: 'Atrasada',
  [ScheduleStatus.CANCELED]: 'Cancelada',
  [ScheduleStatus.RESCHEDULED]: 'Reagendada',
  [ScheduleStatus.INDEFINITE]: 'Indefinida',
}
