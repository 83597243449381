<template>
  <div>
    <div v-if="$user.get().role === 'admin'">
      <v-row class="justify-center">
        <v-col cols="3" md="3">
          <v-autocomplete
            v-model="form.consultor"
            autocomplete="autocomplete_off_hack_xfr4!k"
            prepend-icon="mdi-account-hard-hat"
            dense
            validate-on-blur
            label="Consultores"
            :items="consultores"
            item-text="Nome"
            item-value="id"
            clearable
            :disabled="loading.consultor"
            :loading="loading.consultor"
            required
          />
        </v-col>
        <v-col cols="3" md="3">
          <v-autocomplete
            v-model="form.agroindustria"
            autocomplete="autocomplete_off_hack_xfr4!k"
            prepend-icon="mdi-factory"
            dense
            validate-on-blur
            label="Agroindustrias"
            :items="agroindustrias"
            item-text="pessoa.pessoajuridica.NomeFantasia"
            item-value="CodAgroindustria"
            clearable
            :disabled="loading.agroindustria"
            :loading="loading.agroindustria"
            required
          />
        </v-col>

        <v-col cols="3" md="3">
          <v-autocomplete
            v-model="form.produtor"
            autocomplete="autocomplete_off_hack_xfr4!k"
            prepend-icon="mdi-account-cowboy-hat"
            dense
            validate-on-blur
            label="Produtores"
            :items="produtores"
            item-text="name"
            item-value="id"
            clearable
            :disabled="loading.produtor"
            :loading="loading.produtor"
            required
          />
        </v-col>

        <v-col class="pa-2" cols="2" sm="2" md="2">
          <v-row justify="end" align="center">
            <v-btn
              style="color: white; border-radius: 8px; margin-left: 40%"
              color="green darken-1"
              @click="search"
            >
              <span style="font-size: 16px; font-weight: 600">Buscar</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div :class="loading.calendar ? 'd-none' : ''">
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Hoje
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 Dias</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              :key="calendarKey"
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="loadSchedules"
            ></v-calendar>

            <Card
              :selected-event="selectedEvent"
              :selected-element="selectedElement"
              :selected-open-value="selectedOpen"
              @update="update"
              @deleteEvent="deleteEvent"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <CalendarInfo />
        </v-col>
        <v-col cols="3">
          <Form
            v-if="farms.length > 0"
            :allfarms="farms"
            :consultores="consultores"
            @update="reloadSchedules"
          />
        </v-col>
      </v-row>
    </div>
    <div v-if="loading.calendar" class="text-center justify-center">
      <v-progress-circular :size="70" color="primary" indeterminate />
      <h3 class="mt-4">Preparando visualização...</h3>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import agroindustriasService from '../../services/api/agroindustry'
import consultantService from '../../services/api/consultant'
import farmService from '../../services/api/farms'
import produtorService from '../../services/api/producers'
import scheduleService from '../../services/api/schedule'

import {
  ScheduleStatusClassColors,
  ScheduleStatusText,
} from '../../utils/enums/ScheduleStatus'
import CalendarInfo from './CalendarInfo.vue'
import Card from './card.vue'
import Form from './form.vue'
export default {
  components: {
    Card,
    Form,
    CalendarInfo,
  },
  data: () => ({
    ScheduleStatusClassColors,
    ScheduleStatusText,
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Dia',
      week: 'Semana',
      day: 'Dia',
      '4day': '4 Dias',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    allSchedules: [],
    consultores: [],
    agroindustrias: [],
    produtores: [],
    farms: [],
    calendarKey: 0,

    form: {
      consultor: '',
      agroindustria: '',
      produtor: '',
    },

    loading: {
      calendar: true,
      consultor: false,
      agroindustria: false,
      produtor: false,
    },

    cache: {},

    user: null,

    colors: [
      'blue darken-1', // planejada
      'red lighten-2', // atrasada
      'teal darken-1', // realizada
      'orange lighten-2', // cancelada
      'blue-grey darken-1', // indefinida
    ],
  }),

  created() {
    this.loadConsultores()
    this.loadAgroindustrias()
    this.loadProdutores()
    this.loadFarms()

    this.externalLink()
  },

  methods: {
    async loadFarms() {
      const result = await farmService.list()
      this.farms = result
    },

    async loadConsultores() {
      this.loading.consultor = true

      const consultores = await consultantService.getConsultores({
        active: true,
      })

      this.consultores = consultores.filter(consultor => {
        return Number(consultor.Status) === 1 ? consultor : null
      })

      this.loading.consultor = false
    },

    async loadAgroindustrias() {
      this.loading.agroindustria = true

      const agroindustrias = await agroindustriasService.list()
      this.agroindustrias = agroindustrias

      this.loading.agroindustria = false
    },

    async loadProdutores() {
      this.loading.produtor = true

      const produtores = await produtorService.list()
      this.produtores = produtores

      this.loading.produtor = false
    },

    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target

        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true)),
        )
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    close() {
      this.selectedOpen = false
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    async loadSchedules({ start, end } = {}) {
      if (!start || !end) {
        const date = this.$refs.calendar.start

        start = {
          date: moment(date).startOf('month').format('YYYY-MM-DD'),
        }

        end = {
          date: moment(date).endOf('month').format('YYYY-MM-DD'),
        }
      }

      this.loading.calendar = true

      if (this.cache[start.date]) {
        this.events = this.cache[start.date]
        this.loading.calendar = false
        this.$refs.calendar.checkChange()
        return
      }

      try {
        const { data } = await scheduleService.index({
          id: null,
          startTime: start.date,
          endTime: end.date,
        })

        this.events = data.map(schedule => ({
          ...schedule,
          name: schedule.title,
          start: new Date(schedule.startTime),
          end: new Date(schedule.endTime),
          color: this.ScheduleStatusClassColors[schedule.status],
          timed: true,
        }))

        this.allSchedules = this.events

        this.search()

        this.loading.calendar = true
        this.$refs.calendar?.checkChange()
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar os agendamentos',
        })
      } finally {
        this.loading.calendar = false
      }

      if (this.$route.query.consultor) {
        this.search()
      }
    },

    reloadSchedules() {
      this.cache = {}
      this.loadSchedules({ startTime: this.focus })
    },

    update(id, form) {
      const index = this.events.findIndex(event => event.id === id)
      this.events[index].status = form.status
      this.events[index].startTime = form.startTime
      this.events[index].endTime = form.endTime
      this.events[index].color = this.ScheduleStatusClassColors[form.status]
    },

    deleteEvent(id) {
      const index = this.events.findIndex(event => event.id === id)
      this.events.splice(index, 1)
    },

    getCache(startTime) {
      const date = new Date(startTime)
      const year = date.getFullYear()
      const month = date.getMonth()

      return this.cache[`${year}-${month}`]
    },

    search() {
      try {
        this.loading.calendar = true

        if (
          !this.form.consultor &&
          !this.form.agroindustria &&
          !this.form.produtor
        ) {
          if (this.events != this.allSchedules) this.calendarKey += 1

          this.events = this.allSchedules

          this.user = null
          this.loading.calendar = false
          return
        }

        this.events = []
        let newEvents = this.allSchedules

        if (this.form.consultor) {
          const filtered = newEvents.filter(
            event => event.user.CodPessoa === this.form.consultor,
          )

          newEvents = [...filtered]

          this.user = this.consultores.find(
            consultor => consultor.id === this.form.consultor,
          )
        }

        if (this.form.agroindustria) {
          const agroindustria = this.agroindustrias.find(
            agroindustria =>
              agroindustria.CodAgroindustria === this.form.agroindustria,
          )

          const farms = agroindustria.agroindustria_fazenda.map(farm => {
            if (farm.DataFim) return

            return farm.CodFazenda
          })

          const filtered = newEvents.filter(event =>
            farms.find(farm => farm === event.farmId),
          )

          newEvents = [...filtered]
        }

        if (this.form.produtor) {
          const filtered = newEvents.filter(
            event => event.fazenda.CodProdutor === this.form.produtor,
          )

          newEvents = [...filtered]
        }

        this.events = newEvents

        this.$refs.calendar.checkChange()
      } catch (error) {
        this.loading.calendar = false
      } finally {
        this.loading.calendar = false
      }
    },

    externalLink() {
      if (this.$route.query.consultor) {
        this.form.consultor = this.$route.query.consultor

        const date = new Date()
        date.setMonth(date.getMonth() - 1)
        this.focus = date
      }
    },
  },
}
</script>
