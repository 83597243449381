<template>
  <v-row justify="start" class="d-flex mx-3" style="display: flex">
    <div
      v-for="item in Object.entries(ScheduleStatusText)"
      :key="item[0]"
      class="d-flex justify-center align-center"
    >
      <v-icon :class="'dot ' + ScheduleStatusClassColors[item[0]]"></v-icon>
      <span class="mx-3 text-h5">{{ item[1] }}</span>
    </div>
  </v-row>
</template>

<script>
import {
  ScheduleStatusClassColors,
  ScheduleStatusText,
} from '../../utils/enums/ScheduleStatus'
export default {
  name: 'CalendarInfo',
  data: () => ({
    ScheduleStatusText,
    ScheduleStatusClassColors,
  }),
}
</script>

<style>
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}
</style>
