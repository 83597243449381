import api from '../../../api/api'
import { _orderByName } from '../../../utils/formatter'

export default {
  list: async () => {
    const data = await api.get.produtores()

    return _orderByName({
      data,
      key: 'name',
    })
  },
}
