import api from '../../../api/api'

export default {
  create: async form => {
    const result = api.save.createSchedule(form)

    return result
  },

  index: async ({ id, startTime, endTime }) => {
    const result = api.get.schedules({ id, startTime, endTime })

    return result
  },

  searchByUser: async id => {
    const result = api.get.searchSchedules(id)

    return result
  },

  patch: async ({ id, form }) => {
    const result = api.patch.schedule(id, form)

    return result
  },

  delete: async id => {
    const result = api.delete.schedule(id)

    return result
  },
}
