<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-8 my-2"
          fab
          dark
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Nova visita</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.title"
                  label="Título *"
                  hint="Título da visita"
                  type="text"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <TDateTimePicker
                  v-if="dialog"
                  v-model="form.startTime"
                  :rules="requiredRules"
                  label="Data e hora *"
                />
              </v-col>
              <v-col cols="2" class="text-center align-self-center">
                <strong class="">até</strong>
              </v-col>
              <v-col cols="5">
                <TDateTimePicker
                  v-if="dialog"
                  v-model="form.endTime"
                  :rules="requiredEndDateTime"
                  label="Data e hora *"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="form.farmId"
                  :items="list.farms"
                  label="Fazenda *"
                  item-text="pessoajuridica.NomeFantasia"
                  item-value="id"
                  hint="Selecione a fazenda"
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="form.reportType"
                  :items="list.reportsType"
                  label="Relatório *"
                  item-text="name"
                  item-value="id"
                  hint="Selecione um relatório"
                  required
                  :rules="requiredRules"
                  :loading="loading.reportsType"
                  :disabled="loading.reportsType"
                ></v-autocomplete>
              </v-col>
              <v-col v-if="$user.get().role === 'admin'" cols="12" sm="6">
                <v-autocomplete
                  v-model="form.userId"
                  :items="list.consultants"
                  label="Consultores *"
                  item-text="Nome"
                  item-value="user_id"
                  hint="Selecione um consultor"
                  required
                  :rules="requiredRules"
                  :loading="loading.consultants"
                  :disabled="loading.consultants"
                  @change="onChangeConsultant"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  name="input-7-1"
                  label="Descrição"
                  hint="Descrição da visita"
                  line-numbers="2"
                  clearable
                  counter
                  prepend-icon="mdi-information-outline"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <small>* indica um campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="loading.save"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import TDateTimePicker from '../../components/base/DateTimePicker.vue'
import consultantService from '../../services/api/consultant'

import reportTypeService from '../../services/api/reportType'
import scheduleService from '../../services/api/schedule'

export default {
  name: 'Form',

  components: {
    TDateTimePicker,
  },

  emits: ['update'],

  props: {
    allfarms: {
      type: Array,
      default: () => [],
    },

    consultores: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dialog: false,

      textFieldProps: {
        appendIcon: 'mdi-calendar-clock',
        dense: true,
        errorMessages: [v => !!v || '* Obrigatório'],
      },

      dateProps: {
        min: new Date().toISOString().substr(0, 10),
      },

      form: {
        title: '',
        startTime: null,
        endTime: null,
        farmId: null,
        reportType: null,
        description: '',
        userId: null,
      },

      list: {
        farms: [],
        Allfarms: [],
        reportsType: [],
        consultants: [],
      },

      loading: {
        reportsType: false,
        consultants: false,
        save: false,
      },

      requiredRules: [v => !!v || '* Obrigatório'],

      requiredEndDateTime: [
        v =>
          this.validateEndTime(v) ||
          '* Data e hora final não pode ser menor que a inicial',
        v => !!v || '* Obrigatório',
      ],
    }
  },

  watch: {
    form: {
      handler(val) {
        if (val?.startTime && val?.endTime) {
          const startTime = new Date(val.startTime)
          const endTime = new Date(val.endTime)

          if (startTime > endTime) {
            this.form.endTime = null
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.getReportsType()

    this.list.Allfarms = this.allfarms
    this.list.farms = this.allfarms

    this.list.consultants = this.consultores
  },

  mounted() {
    this.form.userId =
      this.$user.get().role === 'consultor' ? this.$user.get().id : null
  },

  methods: {
    validateEndTime(v) {
      const startTime = new Date(this.form.startTime)
      const endTime = new Date(
        moment(v, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      )

      if (startTime > endTime) {
        this.Toast().fire({
          icon: 'error',
          title: 'Data e hora final não pode ser menor que a inicial',
        })

        return false
      }

      return true
    },

    async getReportsType() {
      this.loading.reportsType = true
      const result = await reportTypeService.list()

      this.list.reportsType = result
      this.loading.reportsType = false
    },

    async getConsultores() {
      this.loading.consultants = true
      const result = await consultantService.list()

      this.list.consultants = result
      this.loading.consultants = false
    },

    async onChangeConsultant() {
      const consultor = this.list.consultants.find(
        consultor => consultor.user_id === this.form.userId,
      )

      this.list.farms = this.list.Allfarms.filter(
        farm => farm.CodConsultorTecnico === consultor.id,
      )
    },

    async save() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha os itens obrigatórios sinalizados com ("*").',
        })
      }

      try {
        this.loading.save = true
        await scheduleService.create({
          ...this.form,
        })

        this.Toast().fire({
          icon: 'success',
          title: 'Visita salva com sucesso.',
        })

        this.dialog = false
        this.clearForm()
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao salvar visita. Tente novamente.',
        })
      } finally {
        this.loading.save = false
        this.$emit('update')
      }
    },

    close() {
      this.clearForm()
      this.dialog = false
    },

    clearForm() {
      this.form = {
        title: '',
        startTime: null,
        endTime: null,
        farmId: null,
        reportType: null,
        description: '',
        userId:
          this.$user.get().role === 'consultor' ? this.$user.get().id : null,
      }
    },
  },
}
</script>
