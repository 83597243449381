<template>
  <v-menu
    v-model="selectedOpen"
    :close-on-content-click="false"
    :activator="selectedElement"
    offset-x
    nudge-left="-12"
    flat
    content-class="elevation-0"
  >
    <v-form ref="form" class="elevation-4">
      <v-card
        class="elevation-4"
        color="grey lighten-4"
        min-width="350px"
        max-width="600px"
        flat
      >
        <v-toolbar :color="selectedEvent.color" dark>
          <v-tooltip
            v-if="ScheduleType.AUTOMATIC === selectedEvent.type"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="small" class="mr-2" v-bind="attrs" v-on="on">
                mdi-laptop
              </v-icon>
            </template>
            <span>Esse agendamento foi criado automaticamente.</span>
          </v-tooltip>

          <v-toolbar-title>{{ selectedEvent?.name }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-btn plain text @click="deleteEvent">
                  <v-icon class="red--text">mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-card-text>
          <v-icon>mdi-barn</v-icon>
          <span class="ml-2">{{
            selectedEvent.fazenda?.pessoajuridica.NomeFantasia
          }}</span>
        </v-card-text>
        <v-card-text>
          <v-icon>mdi-account-cowboy-hat</v-icon>
          <span class="ml-2">{{ selectedEvent.user?.name }}</span>
        </v-card-text>
        <v-card-text>
          <v-row class="flex text-center justify-center align-center">
            <v-icon>mdi-clock-time-eight</v-icon>
            <v-col cols="5">
              <DateTimePicker
                v-model="form.startTime"
                label="Data e hora *"
                :filled="true"
                :rules="requiredRules"
                @onchange="updateDate"
              />
            </v-col>
            <v-col cols="1">
              <v-icon>mdi-arrow-right-thin</v-icon>
            </v-col>
            <v-col cols="5">
              <DateTimePicker
                v-model="form.endTime"
                label="Data e hora *"
                :filled="true"
                :rules="requiredEndDateTime"
                @onchange="updateDate"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-icon>mdi-clipboard-text</v-icon>
          <span class="ml-2">{{ selectedEvent.report_type?.name }} </span>
        </v-card-text>
        <v-card-text>
          <v-icon>mdi-information</v-icon>
          <span class="ml-2">{{ selectedEvent.description }} </span>
        </v-card-text>
        <v-card-text>
          <v-icon>mdi-status</v-icon>
          <v-autocomplete
            v-model="form.status"
            :items="Object.entries(ScheduleStatusText)"
            label="Status *"
            item-text="1"
            item-value="0"
            hint="Selecine o status da visita"
            required
          >
            <template v-slot:selection="data">
              <v-list-item-avatar size="18">
                <v-avatar
                  left
                  :color="ScheduleStatusClassColors[data.item[0]]"
                  size="16"
                  style="border: 1px solid black !important"
                ></v-avatar>
              </v-list-item-avatar>
              <span class="ml-2">
                {{ data.item[1] }}
              </span>
            </template>

            <template v-slot:item="data">
              <v-list-item-avatar size="16">
                <v-avatar
                  left
                  :color="ScheduleStatusClassColors[data.item[0]]"
                  size="16"
                  style="border: 1px solid black !important"
                ></v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item[1] }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="!diff"
            :loading="loading.save"
            text
            color="primary"
            @click="update"
          >
            Salvar
          </v-btn>
          <v-btn text color="red" @click="selectedOpen = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-menu>
</template>

<script>
import moment from 'moment'
import DateTimePicker from '../../components/base/DateTimePicker.vue'
import scheduleService from '../../services/api/schedule'
import {
  ScheduleStatus,
  ScheduleStatusClassColors,
  ScheduleStatusText,
} from '../../utils/enums/ScheduleStatus'
import { ScheduleType } from '../../utils/enums/ScheduleType'
export default {
  name: 'Card',

  components: {
    DateTimePicker,
  },

  emits: ['update', 'deleteEvent'],

  props: {
    selectedEvent: {
      type: Object,
      default: () => ({}),
    },
    selectedElement: {
      type: HTMLElement,
      default: () => ({}),
    },
    selectedOpenValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ScheduleStatus,
      ScheduleStatusClassColors,
      ScheduleStatusText,
      ScheduleType,
      selectedOpen: false,

      form: {
        status: this?.selectedEvent?.status ?? '0',
        startTime: this?.selectedEvent?.startTime ?? '',
        endTime: this?.selectedEvent?.endTime ?? '',
      },

      loading: {
        save: false,
      },

      diff: true,

      requiredRules: [v => !!v || '* Obrigatório'],

      requiredEndDateTime: [
        v =>
          this.validateEndTime(v) ||
          '* Data e hora final não pode ser menor que a inicial',
        v => !!v || '* Obrigatório',
      ],
    }
  },

  watch: {
    selectedOpenValue: {
      handler(val) {
        this.selectedOpen = val
      },
      immediate: true,
    },

    selectedEvent: {
      handler(val) {
        this.form.status = `${val?.status}` ?? '0'
        this.form.startTime = val?.startTime ?? ''
        this.form.endTime = val?.endTime ?? ''
      },
      immediate: true,
    },

    form: {
      handler(val) {
        if (!val) return

        this.diff = Object.entries(val).some(
          ([key, value]) => `${value}` !== `${this.selectedEvent[key]}`,
        )
      },
      immediate: true,
      deep: true,
    },
  },

  created() {},

  methods: {
    validateEndTime(v) {
      const startTime = new Date(this.form.startTime)
      const endTime = new Date(
        moment(v, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      )

      if (startTime > endTime) {
        this.Toast().fire({
          icon: 'error',
          title: 'Data e hora final não pode ser menor que a inicial',
        })

        return false
      }

      return true
    },
    async update() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha os itens obrigatórios sinalizados com ("*").',
        })
      }

      try {
        this.loading.save = true

        await scheduleService.patch({
          id: this.selectedEvent.id,
          form: this.form,
        })

        this.Toast().fire({
          icon: 'success',
          title: 'Visita atualizada com sucesso',
        })

        this.$emit('update', this.selectedEvent.id, this.form)

        this.selectedOpen = false
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar a visita',
        })
      } finally {
        this.loading.save = false
      }
    },

    async deleteEvent() {
      try {
        await scheduleService.delete(this.selectedEvent.id)

        this.Toast().fire({
          icon: 'success',
          title: 'Visita excluída com sucesso',
        })

        this.$emit('deleteEvent', this.selectedEvent.id)

        this.selectedOpen = false
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao excluir a visita',
        })
      }
    },

    updateDate() {
      this.form.status = `${ScheduleStatus.RESCHEDULED}`
    },
  },
}
</script>
